p, h2{
        padding: 0;
        margin: 0px;
    }
.header{
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;
    padding: 0px 90px;
    color: aliceblue;
    align-items: center;


    &>img{
        height: 100px;
        border-radius: 20px;
    }
    &>:nth-child(3){
        position: fixed;
        top: 50px;
        right: 20px;
    }
}


.coach{
    display: flex; 
    align-items: center;
    gap: 40px;
    position: relative;
}
.gallery{
    display: flex;
    gap: 10px;
    text-align: center;

    
}
.image-section>img{
    height: 120px;
    border-radius: 50%;
    opacity: 0.8;
}

.menu-list{
    z-index: 9;
    display: block;
    border: solid rgb(32, 32, 32) 3px;
    border-radius: 5px;
    padding: 0;
    background-color: rgb(50, 50, 50);
    position: absolute;




    &>li{
        cursor: pointer;
        padding: 10px 30px;
        list-style: none;
        text-align: center;
        font-size: 20px;
        transition: all ease-in-out 0.3s;

        &:hover{
            color: orange;
            background-color: rgb(32, 32, 32);
        }

        
    }
    &>li:nth-child(1){
        padding: 5px;
        text-align: end;
        transition: all ease-in-out 0.3s;
        &:hover{
            color: white;
            background-color: rgb(50,50,50);
        }
    }
}
.out-button{
    font-size: 26px;
    &:hover{
        color: orange;
        
    }
}





@media (max-width: 992px){
    .header{
        font-size: 24px;
        margin-top: 30px;
        flex-direction: column;

        &>img{
            margin-bottom: 30px;
        }
    }
    .coach{
        flex-direction: column;
        
    }
    
}

@media (max-width: 767px){
    .image-section>img{
        width: 100px;
        height: 100px;
    }
    .gallery{
        font-size: 16px;
    }
}

@media (max-width: 367px){

    .gallery{
        flex-direction: column;
    }
    .image-section>img{
        width: 130px;
        height: 130px;
    }
    .image-section{
        margin-bottom: 20px;
    }

}
