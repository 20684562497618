.App{

  padding-top: 20px;
}
.day-first{
  margin: 0px 20px;
  border: solid orange 3px;
  font-size: 36px;
  text-align: center;
  padding: 10px 0px;
  border-radius: 10px;
  color: aliceblue;
  font-weight: bold;
}
