
.button-description{
    color: aliceblue;
    background-color: orange;
    padding: 5px 10px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 10px;
    margin: 20px;
    border: solid #ff8800 2px;
    transition: all ease-in-out 0.3s;

    &:hover{
        background-color: #ff8800;
    }
    

}



.description{
    border: solid orange 3px;
    margin: 0px 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    color: aliceblue;

    &>h2{
        text-align: center;
        font-size: 36px;
        
    }
}

.question{
    padding: 20px;
    background-color: rgb(161, 161, 161);
    margin: 20px;
    border-radius: 20px;
    font-size: 18px;

    &>h3{
        font-size: 24px;
    }
}

@media (max-width: 767px){
    .button-description{
        font-size: 18px;
    }
    .description{
        &>h2{
            font-size: 28px; 
        }
    }
    .question{
        font-size: 16px;
        &>h3{
            font-size: 20px;
        }
    }
}