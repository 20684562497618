.time-table{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0px 10px;
    margin-bottom: 150px;
    
}
.container{
    margin: 10px;
    display: flex;
    gap: 10px;
}
.time-section{
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &>h2{
        padding: 0px;
        margin: 0px;
        background-color: orange;
        padding: 5px 10px;
        border: solid rgb(255, 132, 0) 3px;
        border-radius: 10px;
        color: aliceblue;
        text-align: center;
        margin-bottom: 30px;
    }
    &>.time{
        color: aliceblue;
        background-color: orange;
        text-align: center;
        width: max-content;
        margin: 0px auto;
        padding: 5px 10px;
        border: solid rgb(255, 132, 0) 3px;
        border-radius: 10px;
        font-size: 24px;
        font-weight: bold;

    }

}
.list-section{
    background-color: gray;
    padding: 20px;
    border-radius: 20px;
    font-size: 18px;
    color: aliceblue;
    max-width: 300px;

    &>div{
        margin-bottom: 10px;
    }
}

.list-section-radio{
    position: relative;
    padding-left: 20px;

}
.radio{
    position: absolute;
    top: 4px;
    left: 0px
}


@media (max-width: 992px){

}

@media (max-width: 767px){
    .container{
        flex-direction: column;
        align-items: center;
    }
    .time-section{
        align-items: center;
        margin: 10px 0px;

        &>h2{
           font-size: 20px;
           width: 110px;
        }
        &>.time{
            font-size: 20px;
        }
    }
    .time-section{
        margin: 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
    }
    .radio{
        top: 3px;
  
    }

    .list-section{
        font-size: 16px;
        color: aliceblue;
    
        &>div{
            margin-bottom: 10px;
        }
    }
    
    .time-section>h2{
        

    }
}

